<template>
  <div class="gestion-societe-a-traiter style-box-setting">
    <div class="box-content">
      <div class="card-block-filter-setting">
        <div class="right-block-filter">
          <v-progress-circular
            class="ml-2"
            v-if="getLoadingSocieteAtraiter"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </div>
        <div class="left-block-filter">
          <div class="search-input">
            <search
              :value="search"
              @changeSearchValue="search = $event"
              hiddenContent="true"
              placeHolder="Rechercher par sociéte"
            />
          </div>
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center"
                v-on="on"
                v-bind="attrs"
                v-if="checkPermission('GPATADDTH')"
                @click.prevent="addSocieteModal = true"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter une gestion pour sociéte</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="">
      <div class="blocks">
        <div class="permission-hearder">
          <div class="title"></div>
          <div class="fix-height-slide">
            <v-list class="list-category">
              <v-list-item-group>
                <v-list-item
                  button
                  v-for="gestion in getGestionSocieteAtraiter"
                  :key="gestion.id"
                  @click.prevent="handleClickGestion(gestion)"
                  class="custom-list-group custom-list-group-vue"
                >
                  <v-list-item-icon
                    v-if="
                      selectedGestionSociete &&
                        selectedGestionSociete.id == gestion.id
                    "
                  >
                    <font-awesome-icon class="color-cdg" icon="check" />
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      ><span class="capitalize">
                        {{ gestion.name }}</span
                      ></v-list-item-title
                    >
                  </v-list-item-content></v-list-item
                >
              </v-list-item-group></v-list
            >
          </div>
        </div>

        <div class="permission-hearder" v-if="selectedGestionSociete">
          <div class="permission-user">
            <div class="title">Sociétes</div>
            <div class="fix-height-slide" v-if="selectedGestionSociete">
              <v-list class="list-category">
                <v-list-item-group>
                  <v-list-item
                    button
                    v-for="societe in computedListSearch(
                      selectedGestionSociete.societe
                    )"
                    :key="societe.id"
                    class="custom-list-group custom-list-group-vue"
                  >
                    <v-list-item-content>
                      <v-row>
                        <v-checkbox
                          :label="societe.filiale"
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="societe.checked"
                        ></v-checkbox>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item> </v-list-item-group
              ></v-list>
            </div>
            <v-row class="mt-2 ml-4">
              <v-col cols="12">
                <v-btn
                  text
                  @click.prevent.stop="updateFestionSocieteTraiter"
                  title="Enregistrer"
                  :loading="getLoadingAddSocieteAtraiter"
                  :disabled="getLoadingAddSocieteAtraiter"
                  class="btn-action-permession"
                >
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
        <div class="permission-hearder" v-if="selectedGestionSociete">
          <div class="permission-user">
            <div class="title">Interfaces</div>
            <div class="fix-height-slide-inetrafece">
              <v-list class="list-category">
                <v-list-item-group>
                  <v-list-item
                    button
                    v-for="inter in selectedGestionSociete.interface"
                    :key="inter.key"
                    class="custom-list-group custom-list-group-vue"
                  >
                    <v-list-item-content>
                      <v-row>
                        <v-checkbox
                          :label="inter.interface"
                          class="checked-global"
                          color="#704ad1"
                          :value="true"
                          :true-value="true"
                          :false-value="false"
                          hide-details
                          v-model="inter.checked"
                        ></v-checkbox>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item> </v-list-item-group
              ></v-list>
            </div>
            <v-row lass="mt-2 ml-4">
              <v-col cols="12">
                <v-btn
                  text
                  @click.prevent.stop="updateFestionSocieteTraiter"
                  title="Enregistrer"
                  :loading="getLoadingAddSocieteAtraiter"
                  :disabled="getLoadingAddSocieteAtraiter"
                  class="btn-action-permession"
                >
                  Enregistrer
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
    <!-- add Societe modal  -->
    <v-dialog
      v-model="addSocieteModal"
      persistent
      max-width="600px"
      :scrollable="true"
      :hide-overlay="true"
      no-click-animation
      content-class="custom-vuetify-dialog-add"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Ajouter une gestion pour sociéte
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addSocieteModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <v-form class="mt-2" ref="form" lazy-validation>
            <v-row class="mt-2">
              <v-col cols="12">
                <v-text-field
                  label="Nom *"
                  :persistent-placeholder="true"
                  v-model="gestionSocieteToAdd.name"
                  dense
                  outlined
                  required
                  :auto-focus="false"
                  class="msg-error"
                  validate-on-blur
                  :rules="[v => !!v || 'Nom est obligatoire']"
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :clearable="true"
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="gestionSocieteToAdd.societe"
                  :items="computedSociete"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="Sociéte"
                  required
                  return-object
                  item-text="name"
                  dense
                  class="mb-1"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun sociéte trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  :loading="getLoadingSettingFilialeTh"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ gestionSocieteToAdd.societe.length - 0 }} sociéte(s)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :clearable="true"
                  color="#704ad1"
                  item-color="#704ad1"
                  v-model="gestionSocieteToAdd.interface"
                  :items="listInterface"
                  placeholder="Séléctionnez"
                  :persistent-placeholder="true"
                  label="interface"
                  required
                  return-object
                  item-text="name"
                  dense
                  class="mb-1"
                  item-value="id"
                  :auto-focus="false"
                  no-data-text="Aucun interface trouvée"
                  outlined
                  multiple
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                  <template v-slot:selection="{ index }">
                    <span
                      v-if="index === 0"
                      class="text-grey text-caption align-self-center"
                    >
                      +{{ gestionSocieteToAdd.interface.length - 0 }}
                      interface(s)
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form></v-card-text
        >
        <div
          v-if="getErrorAddGestionSocieteAtraiter"
          class="message-error-modal"
        >
          <ul v-if="Array.isArray(getErrorAddGestionSocieteAtraiter)">
            <li
              v-for="(e, index) in getErrorAddGestionSocieteAtraiter"
              :key="index"
            >
              {{ e }}
            </li>
          </ul>
          <div v-else>{{ getErrorAddGestionSocieteAtraiter }}</div>
        </div>
        <div v-if="errorSocieteTraite" class="message-error-modal">
          {{ errorSocieteTraite }}
        </div>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="getLoadingAddSocieteAtraiter"
            :class="{ loader: getLoadingAddSocieteAtraiter }"
            @click.prevent.stop="handleAddGestionSociete"
            >Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addSocieteModal')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      loadingSociete: false,
      loadingInterface: false,
      selectedGestionSociete: null,
      search: null,
      addSocieteModal: false,
      gestionSocieteToAdd: {
        name: null,
        societe: [],
        interface: []
      },
      errorSocieteTraite: null,
      listInterface: [{ name: 'Commercial', id: 'GAPC', checked: false }]
    };
  },
  components: {
    search: () => import('@/views/component/SearchInputComponent.vue')
  },
  methods: {
    ...mapActions([
      'getAllSocieteAtraiters',
      'updateSocieteAtraiter',
      'addNewSocieteAtraiter',
      'getSettingFilialeTh'
    ]),
    handleAddGestionSociete() {
      this.errorSocieteTraite = null;
      if (
        this.gestionSocieteToAdd.societe.length == 0 ||
        this.gestionSocieteToAdd.interface.length == 0
      ) {
        this.errorSocieteTraite =
          this.gestionSocieteToAdd.societe.length == 0
            ? 'sélectionner au moins une sociéte'
            : 'Sélectionner au moins un interface';
      } else {
        let interfaces = [];
        this.listInterface.forEach(item => {
          this.gestionSocieteToAdd.interface.forEach(element => {
            if (element.key == item.key) {
              item.checked = true;
            }
          });
          interfaces.push(item);
        });
        let societe = [];
        this.computedSociete.forEach(item => {
          this.gestionSocieteToAdd.societe.forEach(element => {
            if (element.id == item.id) {
              item.checked = true;
            }
          });
          societe.push(item);
        });
        const response = this.addNewSocieteAtraiter({
          name: this.gestionSocieteToAdd.name,
          societe: societe,
          interface: interfaces
        });
        if (response) {
          this.hideModal('addSocieteModal');
        }
      }
    },
    hideModal(ref) {
      this[ref] = false;
      this.resetModal();
    },
    resetModal() {
      this.errorSocieteTraite = null;
      this.gestionSocieteToAdd = {
        name: null,
        societe: [],
        interface: []
      };
    },
    handleClickGestion(societe) {
      this.selectedGestionSociete = societe;
    },
    async updateFestionSocieteTraiter() {
      const response = await this.updateSocieteAtraiter({
        data: this.selectedGestionSociete
      });
    }
  },
  computed: {
    ...mapGetters([
      'getGestionSocieteAtraiter',
      'getLoadingSocieteAtraiter',
      'checkPermission',
      'getLoadingAddSocieteAtraiter',
      'getLoadingAddSocieteAtraiter',
      'getSettingFilialesTh',
      'getErrorAddGestionSocieteAtraiter',
      'getLoadingSettingFilialeTh'
    ]),
    computedSociete() {
      return this.getSettingFilialesTh
        ?.filter(item => item.deleted_at == null && item.type != 'particulier')
        .map(item => {
          return { ...item, checked: false };
        });
    },
    computedListSearch() {
      return function(data) {
        if (data) {
          let table = data;
          if (this.search != null) {
            table = table.filter(item =>
              item.filiale.toLowerCase().includes(this.search.toLowerCase())
            );
          }
          return table;
        }
      };
    },
    computedChecked() {
      return function(filters) {
        let tableCheck = filters.filter(item => item.checked == true);
        if (tableCheck.length == filters.length) {
          return true;
        }
        return false;
      };
    }
  },
  mounted() {
    this.getAllSocieteAtraiters();
    this.getSettingFilialeTh();
  }
};
</script>

<style lang="scss" scoped>
.gestion-societe-a-traiter {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 0px;
  padding-top: 0px;

  .title {
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    color: #2a2a2a;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 600;
  }

  .permission-hearder {
    // height: 100%;
    padding: 5px;
    padding: 5px 10px;
    background-color: #fff;
    border-radius: 0px;
    width: 34%;
    max-width: 34%;
    margin-right: 10px;
  }
}
.btn-action-permession {
  color: rgb(112, 74, 209);
  caret-color: rgb(112, 74, 209);
  border: 1px solid;
}
.blocks {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5c7;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 0px;
}
.box-content {
  padding-top: 0px !important;
}
.checked-global {
  margin-top: 0px;
}
</style>
<style lang="scss">
.gestion-societe-a-traiter {
  .v-list-item__content {
    padding-left: 10px;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: #4d4bac !important;
    background-color: #4d4bac !important;
  }
  .multiselect__tag {
    background: #4d4bac;
  }
  .multiselect__tag-icon:after {
    color: #ffffff;
  }
  #checkbox-group-1 {
    text-transform: capitalize;
    margin: auto;
    display: table;
  }
  #info-wrap {
    overflow: hidden;
  }

  .description-column {
    width: 72%;
    float: left;
    padding-left: 4px;
    font-size: 11px;
  }
  .info-column {
    width: 11%;
    float: left;
  }
  .interface-permission {
    padding: 6px;
    padding-left: 13px;
    margin-bottom: 9px;
    border: 1px solid #9393bc;
    border-radius: 20px 20px;
    font-size: medium;
  }
  .permission-hearder::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .permission-hearder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .permission-hearder::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }

  .permission-hearder::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .permission-hearder::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .permission-hearder::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  .fix-height-slide {
    display: block;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: calc(100vh - 380px);
    min-height: calc(100vh - 378px);
    padding-right: 9px;
    margin-bottom: 2px;
  }

  .fix-height-slide-inetrafece {
    display: block;
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: calc(100vh - 374px);
    min-height: calc(100vh - 362px);
    padding-right: 9px;
    // margin-bottom: 5px;
  }
  .fix-height-slide::-webkit-scrollbar,
  .fix-height-slide-inetrafece::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  .fix-height-slide::-webkit-scrollbar-track,
  .fix-height-slide-inetrafece::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #fff;
    border-radius: 10px;
  }

  /* Handle */
  .fix-height-slide::-webkit-scrollbar-thumb,
  .fix-height-slide-inetrafece::-webkit-scrollbar-thumb {
    background: #eeeeee;
    border-radius: 10px;
  }

  /* Handle on hover */
  .fix-height-slide::-webkit-scrollbar-thumb:hover,
  .fix-height-slide-inetrafece::-webkit-scrollbar-thumb:hover {
    background: #eeeeee;
  }
}
</style>
